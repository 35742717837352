import { 
  createContext, 
  useCallback,
  useContext, 
  useEffect,
  useMemo, 
  useState 
} from 'react';
import { useAlert } from 'components/provider/Alert';
import { useHelloApi } from 'components/provider/HelloApi';
import { withRouter } from 'react-router';
import Api from 'api';
import Sentry from 'sentry';

export const AuthContext = createContext();

function AuthProvider ({ 
  children, 
  history, 
}) {

  const { alertFailure, clearAlert } = useAlert();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
  const [redirectTo, setLoginRedirectTo] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isFetchingAccount, setIsFetchingAccount] = useState(false);
  const [token, setToken] = useState(undefined);
  const [accounts, setAccounts] = useState(null);
  
  const { 
    isCheckingForMaintenanceMode, 
    isInMaintenanceMode 
  } = useHelloApi();

  const account = useMemo(() => 
    accounts === null 
      ? undefined
      : accounts.find(a => a.isActive) || null, 
    [accounts]
  );
  const exposedToken = useMemo(() => account?.token, [account]);

  function uniqByKeepLast(a, key) {
    return [
        ...new Map(
            a.map(x => [key(x), x])
        ).values()
    ]
  }

  // a component can use this fn to trigger the login flow
  const login = redirectLocation => {
    setIsLoggingIn(true);
    if (redirectLocation) {
      setLoginRedirectTo(redirectLocation);
    }
  }

  function resetStoredAccounts() {
    window?.localStorage?.setItem('accounts', JSON.stringify([]));
  }

  const retrieveStoredAccounts = useCallback(
    () => {
      let result = [];
      const storedAccountsJSON = window?.localStorage?.getItem('accounts');
      if (!storedAccountsJSON) {
        console.log('Stored accounts not found in storage. Initializing stored accounts.');
        resetStoredAccounts();
        return result;
      }
      try {
        result = JSON.parse(storedAccountsJSON);
      } catch (e) {
        console.log('Stored accounts is invalid JSON. Resetting stored accounts.');
        resetStoredAccounts();
        return result;
      }
      if (!Array.isArray(result)) {
        console.log('Stored accounts is not an array. Resetting stored accounts.');
        resetStoredAccounts();
        return [];
      }
      return result;
    },
    []
  );

  const addAccountToStore = useCallback(
    (account) => {
      if (!account) throw new Error('Could not store account - no account details provided.');

      let storedAccounts = retrieveStoredAccounts();

      const newStoredAccounts = storedAccounts
        .map(a => account.isActive ? Object.assign({}, a, { isActive: false }) : a)
        .concat(account);
      const uniqNewStoredAccounts = uniqByKeepLast(newStoredAccounts, acc => acc?.data?.id);
      window?.localStorage?.setItem('accounts', JSON.stringify(uniqNewStoredAccounts));

      return uniqNewStoredAccounts;
    },
    [retrieveStoredAccounts]
  );

  const removeAccountFromStore = useCallback(
    token => {
      if (!token) throw new Error('Could not remove account - no token provided.');

      let storedAccounts = retrieveStoredAccounts();

      const isTokenStored = storedAccounts.some(a => a.token === token);
      if (!isTokenStored) return storedAccounts;

      const newStoredAccounts = storedAccounts
        .filter(a => a.token !== token)
        .map((a, i) => {
          if (i === 0) {
            return Object.assign({}, a, { isActive: true });
          } else {
            return Object.assign({}, a, { isActive: false });
          }
        });
      window?.localStorage?.setItem('accounts', JSON.stringify(newStoredAccounts));

      return newStoredAccounts;
    },
    [retrieveStoredAccounts]
  );

  const markStoredAccountAsActive = useCallback(
    account => {
      if (!account) throw new Error('Could not switch to account - no account provided.');

      let storedAccounts = retrieveStoredAccounts();

      const newStoredAccounts = storedAccounts
        .map(a => {
          if (a.data.id === account.data.id) {
            return Object.assign({}, a, { isActive: true });
          } else {
            return Object.assign({}, a, { isActive: false });
          }
        });

      window?.localStorage?.setItem('accounts', JSON.stringify(newStoredAccounts));

      return newStoredAccounts;
    },
    [retrieveStoredAccounts]
  )

  const switchToAccount = useCallback(
    account => {
      Api.cache.store.store = {};
      const storedAccounts = markStoredAccountAsActive(account);
      setAccounts(storedAccounts);
    },
    [
      markStoredAccountAsActive,
      setAccounts
    ]
  )

  const fetchToken = useCallback(
		async() => {
      try {
        clearAlert();
        const loginResponse = await Api.postForm('/auth/login/', null, {
          username,
          password
        });
        return loginResponse?.data?.data?.attributes?.auth_token;  
      } catch (e) {
        alertFailure("Login failed");
        Sentry.captureException(e);
        return null;
      }
    },
		[
      clearAlert,
      password,
      alertFailure,
      username, 
    ]
	);

  const fetchAndSetToken = useCallback(
		async() => {
      try {
        const token = await fetchToken();
        setToken(token);  
      } finally {
        setIsLoggingIn(false);
      }
    },
		[
      fetchToken,
      setIsLoggingIn,
      setToken
    ]
	);

  const fetchAccount = useCallback(
    async token => {
      try {
        // get the account
        const accountResponse = await Api.get('/accounts/me/', token, {
          include: 'auth_user,youtube_access_tokens'
        }, null, true);
      
        const patreonAccessTokens = accountResponse?.data?.included?.filter(i => i.type === 'PatreonToken');
        const youtubeAccessTokens = accountResponse?.data?.included?.filter(i => i.type === 'YoutubeToken');

        const isStaff = accountResponse?.data?.data?.type === 'User' && accountResponse?.data?.included.some(i => i.attributes.is_staff);
        const isSponsor = accountResponse?.data?.data?.type === 'SponsorUser';
        const isCreator = accountResponse?.data?.data?.type === 'User' && !isStaff;
        const sponsors = (isSponsor && accountResponse?.data?.data?.relationships?.sponsors?.data) || [];
        const account = {
          id: accountResponse?.data?.data?.id,
          auth_user: accountResponse?.data?.data?.relationships?.auth_user?.data?.id,
          isReadOnlyCreator: accountResponse?.data?.included.some(i => i.attributes.read_only),
          isStaff,
          isSponsor,
          isCreator,
          hasConnectedYoutube: youtubeAccessTokens && youtubeAccessTokens.length > 0,
          hasConnectedPatreon: patreonAccessTokens && patreonAccessTokens.length > 0,
          patreonAccessTokens,
          sponsors,
          youtubeAccessTokens,
          ...accountResponse?.data?.data?.attributes
        }
          
        return account;
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        return null;
      }
    },
    []
  );

  const fetchAndStoreAccount = useCallback(
    async({
      token = null,
      makeActive = false, 
      doRedirect = false
    }) => {
      if (!token) return null;
      try {
        if (isInMaintenanceMode || isCheckingForMaintenanceMode) return null;
        const accountData = await fetchAccount(token);
        if (accountData) {
          // add the stored token/account pair to localstorage
          const account = { token, data: accountData, isActive: makeActive };
          const storedAccounts = addAccountToStore(account);
          // sync the stored token/account pairs to our in-memory list
          setAccounts(storedAccounts); 
        } else {
          // if we can't get the account, assume the token is bad
          const storedAccounts = removeAccountFromStore(token);
          // sync the stored token/account pairs to our in-memory list
          setAccounts(storedAccounts);
        }
        // redirect here
        if (doRedirect && redirectTo) {
          history.replace(redirectTo);
          setLoginRedirectTo(null);
        }
      } catch (e) {
        return null;
      }
    },
    [
      addAccountToStore, 
      fetchAccount, 
      history,
      isCheckingForMaintenanceMode,
      isInMaintenanceMode,
      redirectTo,
      removeAccountFromStore,
      setAccounts
    ]
  );

  const refreshAccounts = useCallback(
    accounts => {
      for (const account of accounts) {
        fetchAndStoreAccount({
          token: account.token,
          makeActive: account.isActive,
          doRedirect: false,
        });  
      }
    }, 
    [fetchAndStoreAccount]
  )

  // localStorage.accounts -> accounts
  useEffect(() => {
    try {
      const accounts = retrieveStoredAccounts();
      setAccounts(accounts);
      refreshAccounts(accounts);
    } catch (e) {
      Sentry.captureException(e); // we're only capturing fatal errors here
      setAccounts([]);
    }
  }, [
    refreshAccounts,
    retrieveStoredAccounts,
    setAccounts
  ]);

  // when isLoggingIn changes to true, request a new token with the current credentials
  useEffect(() => {
    if (isLoggingIn) {
      fetchAndSetToken();
    }
	}, [
    username,
    password,
    isLoggingIn, 
    fetchAndSetToken, 
    setIsLoggingIn
  ]);

  // when the token changes to a truthy value, fetch the related account
  useEffect(() => {
    if (token) {
      fetchAndStoreAccount({
        token,
        makeActive: true,
        doRedirect: true,
      });
    }
    return () => {
      setToken(null);
      setIsFetchingAccount(false);  
    }  
  }, [
    fetchAndStoreAccount, 
    setToken,
    token
  ]);

  const logoutAccount = useCallback(
		account => {
      Api.cache.store.store = {};
      const storedAccounts = removeAccountFromStore(account.token);
      setAccounts(storedAccounts);
    },
		[
      removeAccountFromStore,
      setAccounts
    ]
	);

  return (
    <AuthContext.Provider 
      value={{
        account,
        accounts,
        isFetchingAccount,
        isLoggingIn,
        login,
        setLoginRedirectTo,
        setUsername,
        setPassword,
        switchToAccount,
        token: exposedToken,
        logoutAccount,
      }}
    >
      { children }
    </AuthContext.Provider >
  );
}

export const withAuth = WrappedComponent => props => {
  const auth = useAuth();
  return <WrappedComponent {...props} {...auth} />;
};

export const useAuth = () => useContext(AuthContext);

export default withRouter(AuthProvider);
