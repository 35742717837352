import { createContext, useCallback, useContext, useEffect, useState, useMemo, useRef } from 'react';
import { withRouter } from 'react-router';
import { setupCache } from 'axios-cache-adapter';
// import { Deserializer } from 'jsonapi-serializer';
import axios from 'axios';
import Util from 'utilities';
import Sentry from 'sentry';
import MaintenanceModeView from './view.js';

export const HelloApiContext = createContext();

function HelloApi({ 
  children, 
}) {

  const SHOULD_CHECK_API_STATUS = false;
  const [isInMaintenanceMode, setIsInMaintenanceMode] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState('');
  const [isCheckingForMaintenanceMode, setIsCheckingForMaintenanceMode] = useState(SHOULD_CHECK_API_STATUS); // this is used to prevent the app from rendering until we've initially checked the API status

  const clientRef = useRef(null);

  // Set up the axios instance
  useEffect(() => {
    const API_HOST = process.env.REACT_APP_HELLO_API_URL;

    // Setting up the cache
    const cache = setupCache({
      invalidate: async (config, request) => {
        if (request.clearCacheEntry) {
          await config.store.removeItem(config.uuid)
        }
      },
      maxAge: 15 * 60 * 1000,
      debug: false,
      exclude: {
        query: false
      },
      key: request => `${request.url}?${Util.url.serialize(request.params)}`
    });

    // Setting up axios instance
    const axiosInstance = axios.create({
      baseURL: API_HOST,
      adapter: cache.adapter,
    });

    // Store the axios instance in a ref (preferred over state because it will not change and should not cause a re-render)
    clientRef.current = axiosInstance

    return () => {
      // Given that this provider sits at such a high level in the app, we do not need to worry about
      // cleaning up the axios instance. It will be cleaned up when the app is unmounted.
    };
  }, []);

  // TODO: Implement API methods for get, post, etc. and use them in the app

  // Define a function to fetch the API status (maintenance mode)
  const fetchStatus = useCallback(async () => {
    const client = clientRef.current;
    if (!client) return;
    let status;
    try {
      await client.get('/maintenance', {
        headers: {
          'Content-Type': 'application/vnd.api+json'
        },    
        cache: {
          ignoreCache: true // This tells axios-cache-adaptor to ignore the cache and make a fresh request
        }
      });
      // the /maintenance endpoint does not exist so we're always going to throw an error
    } catch (e) {
      status = {
        code: e.response.status,
        body: e.response.status === 503 ? e.response.data : ''
      }
      // intentionally swallow the error
    }
    return status;
  }, [clientRef]);

  // Check the API status on mount
  useEffect(() => {
    if (!SHOULD_CHECK_API_STATUS) return;

    const checkAPIStatus = async () => {
      try {
        setIsCheckingForMaintenanceMode(true);
        const apiStatus = await fetchStatus();
        setIsInMaintenanceMode(apiStatus.code === 503);
        setMaintenanceMessage(apiStatus.body);
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        setIsCheckingForMaintenanceMode(false);
      }
    };

    checkAPIStatus();
  }, [SHOULD_CHECK_API_STATUS, fetchStatus]);

  // Check the API status every 60 seconds
  useEffect(() => {
    if (!SHOULD_CHECK_API_STATUS) return;

    const interval = setInterval(async () => {
      try {
        const apiStatus = await fetchStatus();
        setIsInMaintenanceMode(apiStatus.code === 503);
        setMaintenanceMessage(apiStatus.body);
      } catch (e) {
        Sentry.captureException(e);
      }
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [SHOULD_CHECK_API_STATUS, fetchStatus]);

  const contextValue = useMemo(() => ({
    clientRef,
    isCheckingForMaintenanceMode,
    isInMaintenanceMode,
    maintenanceMessage,
  }), [
    clientRef,
    isCheckingForMaintenanceMode, 
    isInMaintenanceMode, 
    maintenanceMessage
  ]);

  return (
    <HelloApiContext.Provider value={contextValue}>
      { !isCheckingForMaintenanceMode && isInMaintenanceMode && <MaintenanceModeView message={maintenanceMessage} /> }
      { !isCheckingForMaintenanceMode && !isInMaintenanceMode && children }
    </HelloApiContext.Provider>
  );
}

export const withHelloApi = WrappedComponent => props => {
  const helloAPIStatus = useHelloApi();
  return <WrappedComponent {...props} {...helloAPIStatus} />;
};

export const useHelloApi = () => useContext(HelloApiContext);

export default withRouter(HelloApi);
